import { useSelector, useDispatch } from "react-redux"
import {
  bracketOrder, coverOrder, editRegularOrder, regularOrder,
} from "@redux/sagas/trades/tradesSlice"
import { setOrderSliceConsentModal } from "@redux/sagas/users/usersSlice"
import type { RootState } from '@redux/store'
import { IBracketInputValues } from "@components/watchlist/tradeModal/types"
import { useEffect, useState } from "react"
import { createSlicedQuantities } from "@utils/orders"
import {
  OrderTypeML, OrderTypeSLM, varietyValues,
} from "../../utils/constant"

// PS
// To determine if the order is AMO, there are two cases
// 1. if an open order is being modified, so we check the isAmo flag in the handleProceedOrder function
// 2. if a new order is being placed, so we read the value from activeTab

interface IOrderProps {
  orderTypeMLVal: string
  priceField: number | undefined
  activeTab: string
  triggerPriceField: number | undefined,
  bracketInputValues: IBracketInputValues
  qty: number
}

interface IHandleOrderProps {
  token: number | undefined
  selectedExchange: string
  quantity: number
  disclosedQty: number
  productValue: string
  buySellToggle: boolean
  orderTypeMLValue: string
  validityVal: string
  symbol:string
  isAmo: boolean
}

const useOrderWL = ({
  orderTypeMLVal,
  priceField,
  activeTab,
  triggerPriceField,
  bracketInputValues,
  qty,
}: IOrderProps) => {
  const dispatch = useDispatch()
  const {
    tradeModalInfo: { editOrderId = "" },
  } = useSelector((state: RootState) => state.trade);
  const { orderSlicing } = useSelector((state: RootState) => state.user.preferences.data)
  const riskApiResponse = useSelector((state: RootState) => state.trade.freezeQty)
  const [isOrderSlicingReq, setIsOrderSlicingReq] = useState(false)
  const [freezeQty, setFreezeQty] = useState(0)

  // check if order slicing is required and set the freeze qty
  useEffect(() => {
    if (riskApiResponse.data) {
      if (riskApiResponse.data.length > 0) {
        const { data } = riskApiResponse
        if (qty > data[0].freezeQty) {
          setFreezeQty(data[0].freezeQty)
          setIsOrderSlicingReq(true)
        }
      }
    } else {
      setIsOrderSlicingReq(false)
    }
  }, [orderSlicing, riskApiResponse])

  const handlePriceVal = () => {
    if (
      orderTypeMLVal === OrderTypeML[0].value
      || orderTypeMLVal === OrderTypeSLM[1].value
    ) {
      return 0
    }
    return Number(priceField)
  }

  const handleTriggerPriceVal = () => {
    if (activeTab === varietyValues.COVER) {
      return triggerPriceField
    } if (
      orderTypeMLVal === OrderTypeML[0]?.value
      || orderTypeMLVal === OrderTypeML[1]?.value
    ) {
      return 0
    }
    return Number(triggerPriceField)
  }

  const handleOrder = ({
    token,
    selectedExchange,
    quantity,
    disclosedQty,
    productValue,
    buySellToggle,
    orderTypeMLValue,
    validityVal,
    symbol,
    isAmo,
  }: IHandleOrderProps) => {
    const payload = {
      exchange: selectedExchange,
      token: token?.toString(),
      quantity: Math.abs(quantity)?.toString(),
      disclosedQty: disclosedQty?.toString(),
      product: productValue,
      transactionType: buySellToggle ? "S" : "B",
      order: orderTypeMLValue,
      price: handlePriceVal()?.toString(),
      validity: validityVal,
      amo: activeTab === varietyValues?.AMO || isAmo,
      triggerPrice: handleTriggerPriceVal()?.toString(),
      tags: "",
      symbol,
    }

    // Check order slicing consent for all order types
    if (isOrderSlicingReq && !orderSlicing) {
      dispatch(setOrderSliceConsentModal(true))
      return;
    }

    if (editOrderId) {
      dispatch(
        editRegularOrder({
          data: {
            ...payload,
            bookLossPrice: "0",
            bookProfitPrice: "0",
          },
          orderId: editOrderId,
        })
      )
    } else if (activeTab === varietyValues.COVER) {
      delete payload.triggerPrice
      dispatch(
        coverOrder({
          ...payload,
          bookLossPrice: handleTriggerPriceVal()?.toString(),
        })
      )
    } else if (activeTab === varietyValues.BRACKET) {
      const { stopLossPrice, profitPrice, trailingSL } = bracketInputValues
      const bracketPayload = {
        ...payload,
        token: payload.token?.toString() as string,
        bookLossPrice: stopLossPrice.toString(),
        bookProfitPrice: profitPrice.toString(),
        trailingPrice: trailingSL === 0 ? "" : trailingSL?.toString() || "",
        amo: false,
      }

      if (!isOrderSlicingReq) {
        dispatch(bracketOrder(bracketPayload));
        return;
      }

      const slicedBracketOrders = createSlicedQuantities(qty, freezeQty);
      slicedBracketOrders.forEach((slicedQty) => {
        dispatch(bracketOrder({ ...bracketPayload, quantity: slicedQty.toString() }));
      });
    } else {
      if (!isOrderSlicingReq) {
        dispatch(regularOrder([payload]));
        return;
      }
      const slicedOrders = createSlicedQuantities(qty, freezeQty);
      for (let i = 0; i < slicedOrders.length; i++) {
        dispatch(regularOrder([{ ...payload, quantity: slicedOrders[i].toString() }]));
      }
    }
  }

  return {
    handleOrder, handlePriceVal, handleTriggerPriceVal,
  }
}

export default useOrderWL
