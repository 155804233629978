import { Text } from '@mantine/core'
import { daySuffix, removeEQFromSymbol } from '../../utils'

const FormattedName = ({
  name,
  isWeekly,
  expiryDay = 0,
  symbol = "",
  size = "sm",
  className = '',
  isWatchlist = false,
  onClick,
}: {
  name: string,
  isWeekly: boolean,
  expiryDay: number
  symbol?: string,
  size?: "xs" | "xsm" | "sm" | "md" | "lg" | "xl"
  className?: string,
  isWatchlist? : boolean,
  onClick?: () => void,
}) => {
  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    onClick?.();
  };

  return isWeekly ? (
    <Text
      className={className}
      size={size}
      onClick={handleClick}
      sx={{
        cursor: onClick ? 'pointer' : 'default',
        transition: 'color 0.2s',
        '&:hover': onClick ? {
          color: 'red',
        } : undefined,
      }}
    >
      {name.split(" ")[0]}
      {' '}
      {expiryDay > 0 && (
        <span>
          {expiryDay}
          <sup>{daySuffix[expiryDay] || 'th'}</sup>
          {isWatchlist && isWeekly ? <sup className="weeklySymbol">w</sup> : null}
        </span>
      )}
      {' '}
      {name.split(" ").slice(2).join(" ")}
    </Text>
  ) : (
    <Text
      className={className}
      size={size}
      onClick={handleClick}
      sx={{
        cursor: onClick ? 'pointer' : 'default',
        transition: 'color 0.2s',
        '&:hover': onClick ? {
          color: 'red',
        } : undefined,
      }}
    >
      {name?.toUpperCase() || removeEQFromSymbol(symbol)?.toUpperCase()}
    </Text>
  );
}

export default FormattedName
