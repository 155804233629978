import { Flex } from '@uikit';
import { Text, useMantineTheme } from '@mantine/core';
import { IHoldingItem } from '@redux/sagas/holdings/holdingsTypes';
import { extractTradeModalData, parsedSymbol } from '@utils/index';
import FormattedName from '@components/watchlist/formattedName';
import HoldingsDropdown from '@containers/holdings/holdingsDropdown';
import { setTradeModalInfo } from '@redux/sagas/trades/tradesSlice';
import { useDispatch } from 'react-redux';

interface IHoldingInstrument {
  row: IHoldingItem,
  width?: number,
}

const HoldingInstrument = ({
  row,
  width = 200,
}: IHoldingInstrument) => {
  const theme = useMantineTheme()
  const { symbol, exchange } = row;
  const parsedSymbolObj = parsedSymbol(symbol, exchange, row.token)
  const dispatch = useDispatch();

  const handleSelect = (item: IHoldingItem, isBuy: boolean) => {
    const { effectiveQty } = item;
    dispatch(
      setTradeModalInfo({
        currentAssetItem: {
          ...extractTradeModalData(item),
          quantity: effectiveQty,
          product: 'C',
        },
        buySellToggle: isBuy,
        showTradeModal: true,
      })
    );
  };

  return (
    <div style={{ width: `${width}px` }} className="holding-intrument">
      <Flex align="baseline">
        {parsedSymbolObj ? (
          <FormattedName
            name={parsedSymbolObj?.niceName}
            expiryDay={parsedSymbolObj?.expiryDay || 0}
            isWeekly={parsedSymbolObj?.isWeekly}
            onClick={() => handleSelect(row, true)}
          />
        ) : (
          <p>N/A</p>
        )}
        <Text size={10} ml={3} color={theme.other.colors.fg.muted}>
          {row.exchange}
        </Text>
      </Flex>
      <HoldingsDropdown row={row} />
    </div>
  );
}

export default HoldingInstrument
