import { Flex } from '@uikit';
import { Text, useMantineTheme } from '@mantine/core';
import { IHoldingItem } from '@redux/sagas/holdings/holdingsTypes';
import { IGttOrderDetail, IUserOrder } from '@redux/sagas/orders';
import { parsedSymbol } from '@utils/index';
import { IPositionItem } from '@redux/sagas/positions';
import FormattedName from '@components/watchlist/formattedName';
import OpenDropdown from '@containers/orders/open/openDropdown';
import { useModifyOrder } from '@hooks/orders';

interface ITableInstrumentItem {
  row: IHoldingItem | IUserOrder | IPositionItem | IGttOrderDetail,
  width?: number
  handleOrderDetailsModal?: any
  handleShowCancel?: any
}

const OpenInstrument = ({
  row,
  width = 200,
  handleOrderDetailsModal,
  handleShowCancel,
}: ITableInstrumentItem) => {
  const theme = useMantineTheme()
  const { symbol, exchange } = row;
  const parsedSymbolObj = parsedSymbol(symbol, exchange, row.token);
  const { handleModifyOrder } = useModifyOrder()

  return (
    <div
      style={{ width: `${width}px` }}
      className="table-intrument"
    >
      <Flex align="baseline">
        {parsedSymbolObj && (
        <span>
          <FormattedName
            name={parsedSymbolObj.niceName}
            expiryDay={parsedSymbolObj.expiryDay}
            isWeekly={parsedSymbolObj.isWeekly}
            onClick={() => handleModifyOrder(row as IUserOrder)}
          />
        </span>
        )}
        <Text size={10} ml={3} color={theme.other.colors.fg.muted}>
          {row.exchange}
        </Text>
      </Flex>
      <OpenDropdown
        row={row}
        handleOrderDetailsModal={handleOrderDetailsModal}
        handleShowCancel={handleShowCancel}
      />
    </div>
  )
}

export default OpenInstrument;
