import { createStyles } from "@mantine/core";
import { getTypographyStyles } from "@theme/themeConfig";

const useStyles = createStyles((theme) => {
  const { colors } = theme.other;

  return {
    container: {
      height: "18rem",
      width: "33%",
      border: `1px solid ${colors.disabled.muted}`,
      borderRadius: "0.5rem",
      overflow: "hidden",
      boxShadow: theme.other.shadow.xs,
      [`@media (max-width: ${768}px)`]: {
        width: "100%",
      },
      backgroundColor: colors.base.white,
    },
    topBorder: {
      height: "4px",
      width: "100%",
      backgroundColor: colors.primary.default,
    },
    content: {
      padding: "1rem 1.3rem",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      height: "100%",
    },
    badgeTag: {
      backgroundColor: colors.disabled.muted,
      padding: "0 0.5rem",
      borderRadius: "1rem",
      display: "inline-block",
      width: "fit-content",
      cursor: "default",

      ...getTypographyStyles("content_2xs_regular"),
    },
    featuresContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "0.5rem",
    },
    featureItem: {
      display: "flex",
      alignItems: "center",
      gap: "0.5rem",
    },
    titleContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "0.5rem",
    },
  };
});

export default useStyles;
