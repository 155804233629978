/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import {
  Menu, Portal, createStyles, Box,
  Flex, Divider,
} from '@mantine/core'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  Copy, Dots, Edit, InfoCircle, X,
} from 'tabler-icons-react'
import { useFocusWithin } from '@mantine/hooks'
import useGetSocketVal from 'src/hooks/common/useGetSocketVal'
import { IGttOrderDetail, IUserOrder } from '@redux/sagas/orders'
import { setTradeModalInfo } from '@redux/sagas/trades/tradesSlice'
import { IHoldingItem } from '@redux/sagas/holdings/holdingsTypes'
import { IPositionItem } from '@redux/sagas/positions'
import CommonTableDropdown from '@components/table/commonTableDropdown'
import { extractTradeModalData, getDropdownCoordinates } from '@utils/index'
import { useModifyOrder } from '@hooks/orders'

export const useStyles = createStyles((theme, { showMenu }: { showMenu: boolean }) => {
  const { colors } = theme.other
  return {
    menuWrapper: {
      border: `1px solid ${colors.disabled.subtle}`,
      borderRadius: 3,
      padding: '0 2px',
      height: 20,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      visibility: showMenu ? 'visible' : 'hidden',
      paddingTop: '5px',
    },
    menuPopupWrapper: {
      position: "absolute",
      zIndex: 500,
      height: 'min-content',
    },
    menuItemLabel: {
      display: 'flex',
      alignItems: 'center',
      gap: 5,
      justifyContent: 'center',
      width: '100%',
      flexDirection: 'column',
    },
  }
});

interface ITableDropdown {
  row: IUserOrder | IHoldingItem | IGttOrderDetail | IPositionItem,
  handleOrderDetailsModal?: (orderId: IUserOrder) => void;
  handleShowCancel?: (item: IUserOrder) => void;
}

const OpenDropdown = ({
  row,
  handleOrderDetailsModal = () => { },
  handleShowCancel = () => { },
}: ITableDropdown) => {
  const [showMenu, setShowMenu] = useState(false);
  const { handleModifyOrder } = useModifyOrder()

  const { classes, cx } = useStyles({ showMenu });
  const dispatch = useDispatch();
  const { ref, focused } = useFocusWithin();
  // snoOrderID is only present in executed cover and bracket order which has to be exited from the open orders
  const isBracketOrCoverOrder = !!(row as IUserOrder).snoOrderID;

  const handleShowMenuDropdown = (isOpen: boolean) => {
    setShowMenu(isOpen)
  }
  useEffect(() => {
    handleShowMenuDropdown(focused)
  }, [focused])

  const { ltp } = useGetSocketVal(Number(row.token), true)

  const handleRepeatOrder = (item: IUserOrder) => {
    const { transactionType } = item;
    dispatch(
      setTradeModalInfo({
        currentAssetItem: extractTradeModalData(item, ltp),
        buySellToggle: transactionType !== "B",
        showTradeModal: true,
      })
    );
  }
  const handleModifyOrderClick = () => {
    handleModifyOrder(row as IUserOrder);
  };

  const handleCancelOrderClick = () => {
    handleShowCancel(row as IUserOrder)
  }

  return (
    <span ref={ref} className={cx(!showMenu ? 'dots' : null, classes.menuWrapper)}>
      <Menu
        shadow="md"
        width={200}
        transitionProps={{ duration: 150 }}
        position="bottom-end"
        withArrow
        offset={-25}
        opened={showMenu}
        onChange={() => handleShowMenuDropdown(!showMenu)}
      >
        <Box onMouseLeave={() => handleShowMenuDropdown(false)}>
          <Menu.Target>
            <span role="button" onClick={(e) => e.stopPropagation()}>
              <Dots cursor="pointer" />
            </span>
          </Menu.Target>
          <Portal>
            <Menu.Dropdown
              sx={{
                top: getDropdownCoordinates().top,
                right: getDropdownCoordinates().right,
                bottom: getDropdownCoordinates().bottom,
                left: getDropdownCoordinates().left,
              }}
              className={cx(classes.menuPopupWrapper, 'tableDropDowns')}
              onClick={(e) => e.stopPropagation()}
            >
              {
                !isBracketOrCoverOrder && (
                  <>
                    <Flex>
                      <Menu.Item
                        onClick={handleModifyOrderClick}
                        h={50}
                      >
                        <Box className={classes.menuItemLabel}>
                          <Edit size={14} />
                          <p className="content_xs_regular">Modify</p>
                        </Box>
                      </Menu.Item>
                      <Menu.Item
                        onClick={handleCancelOrderClick}
                        h={50}
                      >
                        <Box className={classes.menuItemLabel}>
                          <X size={14} />
                          <p className="content_xs_regular">Cancel</p>
                        </Box>
                      </Menu.Item>

                    </Flex>
                    <Divider color="gray.3" />
                  </>
                )
              }
              {/* in executed cover and bracket order there is no option to cancel or modify */}
              {!isBracketOrCoverOrder && (
              <Menu.Item
                icon={<Copy size={14} />}
                onClick={() => handleRepeatOrder(row as IUserOrder)}
              >
                Repeat
              </Menu.Item>
              )}
              <Menu.Item
                icon={<InfoCircle size={14} />}
                onClick={() => handleOrderDetailsModal(row as IUserOrder)}
              >
                Info
              </Menu.Item>
              <CommonTableDropdown row={row} />
            </Menu.Dropdown>
          </Portal>
        </Box>
      </Menu>
    </span>
  )
}

export default OpenDropdown
