import "react-virtualized/styles.css";
import "./app.css";
import "./redux/api/axiosInterceptors";
import {
  ColorScheme,
  ColorSchemeProvider,
  MantineProvider,
} from "@mantine/core";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Notifications } from "@mantine/notifications";
import { useHotkeys } from "@mantine/hooks";
import { RootState } from "@redux/store";
import { appLoginGenerateToken } from "@redux/sagas/auth/authSlice";
import { FAILURE, SUCCESS } from "@utils/constant";
import usePreventKeyboardScrolling from "@hooks/common/usePreventKeyboardScrolling";
import QuantinstiLoader from "@components/loading/quantinstiLoader";
import AppRoutes from "./routes";
import { getCookie, parseQuery, setCookie } from "./utils";
import RenderGlobalComp from "./components/renderGlobalComp";
import GlobalStyles from "./theme/globalStyles";
import { getCustomTheme } from "./theme/theme";

const App = (): JSX.Element => {
  const dispatch = useDispatch();
  usePreventKeyboardScrolling();
  const selectedColorScheme = getCookie("tiqs-theme") as ColorScheme;
  const [colorScheme, setColorScheme] = useState<ColorScheme>(
    selectedColorScheme || "light"
  );
  const [isAppLogin, setIsAppLogin] = useState(
    window.location.pathname === "/app/login"
  );

  const appLoginState = useSelector(
    (state: RootState) => state.auth.appLoginGenerateToken
  );

  // Setting Light theme as default for first time user
  useEffect(() => {
    if (!selectedColorScheme) {
      setCookie("tiqs-theme", "light");
    }
  }, []);

  useEffect(() => {
    setColorScheme(selectedColorScheme);
  }, [selectedColorScheme]);

  const toggleColorScheme = (value?: ColorScheme) => {
    const tempColorScheme = value || (colorScheme === "dark" ? "light" : "dark");
    setColorScheme(tempColorScheme);
    setCookie("tiqs-theme", tempColorScheme);
  };

  const session = getCookie("session");
  const token = getCookie("token");
  useHotkeys([["mod+J", () => toggleColorScheme()]]);

  // App login flow incase of user logged in
  useEffect(() => {
    if (isAppLogin && token && session) {
      const searchParams = parseQuery(window.location.search);
      if (searchParams.appId) {
        const { appId, ...restParams } = searchParams;
        dispatch(appLoginGenerateToken({
          apiKey: appId,
          urlParams: restParams,
        }));
      }
    } else {
      setIsAppLogin(false);
    }
  }, [dispatch, token, session]);

  // Redirecting user incase of app login
  useEffect(() => {
    if (appLoginState.status === SUCCESS) {
      window.location.href = appLoginState.data.redirectUrl;
    } else if (appLoginState.status === FAILURE) {
      setIsAppLogin(false);
    }
  }, [appLoginState]);

  return (
    <ColorSchemeProvider
      colorScheme={colorScheme}
      toggleColorScheme={toggleColorScheme}
    >
      <MantineProvider
        withCSSVariables
        withGlobalStyles
        withNormalizeCSS
        theme={getCustomTheme(colorScheme)}
      >
        <GlobalStyles />
        <Notifications
          autoClose={3000}
          limit={100}
          position="bottom-right"
        />
        {isAppLogin ? (
          <div className="center">
            <QuantinstiLoader />
          </div>
        ) : (
          <AppRoutes />
        )}
        <RenderGlobalComp />
      </MantineProvider>
    </ColorSchemeProvider>
  );
};

export default App;
