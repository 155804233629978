import { Button, Text } from '@mantine/core'
import { Check } from 'tabler-icons-react';
import useStyles from "./exploreCard.styles";

interface ExploreCardProps {
  title: string;
  description: string;
  url: string | undefined;
  tag: string;
  features: string[];
}

const ExploreCard = ({
  title, description, url, tag, features,
}: ExploreCardProps) => {
  const { classes } = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.topBorder} />
      <div className={classes.content}>
        <div className={classes.titleContainer}>
          <Text className="heading_md_semibold">{title}</Text>
          <Text className={classes.badgeTag}>{tag}</Text>
        </div>
        <Text className="content_sm_medium text_fg_subtle">{description}</Text>
        <div className={classes.featuresContainer}>
          {features.map((feature) => (
            <div className={classes.featureItem}>
              <Check size={18} color="#963a72" />
              <Text className="content_sm_regular text_fg_subtle">{feature}</Text>
            </div>
          ))}
        </div>
        <Button fullWidth onClick={() => window.open(url, "_blank")}>
          Explore
          {" "}
          {title}
        </Button>
      </div>
    </div>
  )
}

export default ExploreCard
