import optionChain from "@assets/images/optionChain.webp"
import fatafat from "@assets/images/fatafat.webp"
import algo from "@assets/images/algo.webp"

const algoUrl = "https://algos.tiqs.in"
const fatafatUrl = process.env.REACT_APP_FATAFAT_URL
const optionUrl = process.env.REACT_APP_OPTION_URL

const exploreCards = [
  {
    title: "Algo Trading",
    description: "Enterprise-Level Algorithmic Trading",
    img: algo,
    imgAlt: "Algo",
    imgBgClass: "yellowGradient",
    url: algoUrl,
    features: [
      "Automates trades execution",
      "Consistent strategies",
      "Emotion free trades",
      "Faster execution",
    ],
    tag: "For Automated Trading",
  },
  {
    title: "Fatafat",
    description: "Lightning-Fast Trade Execution",
    img: fatafat,
    imgAlt: "Fatafat",
    imgBgClass: "greenGradient",
    url: fatafatUrl,
    features: [
      "Auto TL Stop Loss",
      "1 Click SL change",
      "1 Click quantity update",
      "Single Screen Trade",
    ],
    tag: "For Scalpers",
  },
  {
    title: "Option Chain",
    description: "Professional-Grade Options Analysis",
    img: optionChain,
    imgAlt: "Option chain",
    imgBgClass: "blueGradient",
    url: optionUrl,
    features: [
      "Real time Greeks",
      "1 Click Basket execution",
      "Margin benefit included",
      "Equity options included",
    ],
    tag: "For Advanced Traders",
  },

]

const cards = [
  {
    id: 1,
    title: "Earn Now",
    heading: "Referral scheme",
    description: "Refer your friends and earn 30% of their brokerage as bonus!",
  },
  // {
  //   id: 2,
  //   title: "Trade Now",
  //   heading: "Fatafat Scalping",
  //   description: "Trail your stop loss and target with a simple click!",
  // },
  // {
  //   id: 3,
  //   title: "Algos Now",
  //   heading: "Algos for everyone",
  //   description: "Unlock the full power of Algo trading!",
  // },
];

export { exploreCards, cards }
