import {
  Box, createStyles, Tooltip,
} from "@mantine/core"
import { useDisclosure, useLocalStorage, useSessionStorage } from "@mantine/hooks"
import { memo, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { selectSelectedWLIndex } from "@redux/sagas/watchlists/watchlistSelectors"
import { clearAddWLItem, setSelectedWatchlistIndex } from "@redux/sagas/watchlists/watchlistsSlice"
import WatchlistSettings from "./watchlistSettingsPopover"

const useStyles = createStyles((theme) => {
  const { colors } = theme.other
  return {
    watchlistFooter: {
      display: "flex",
      position: "fixed",
      width: `${theme.other.watchlistWidth}px`,
      bottom: 0,
      background: colors.base.white,
      borderTop: `1px solid ${colors.disabled.muted}`,
      borderRight: `1px solid ${colors.disabled.muted}`,
      zIndex: 50000,
    },
    watchlistFooterItem: {
      padding: "10px 19px",
      cursor: "pointer",
      color: "#9B9B9B",
      fontSize: 14,
      borderRight:
        theme.colorScheme === "dark" ? "1px solid #232325" : "1px solid #eee",
      "&:hover": {
        backgroundColor: theme.colorScheme === "dark" ? "#131415" : "#f9f9f9",
      },
    },
    selectedIndex: {
      backgroundColor: theme.colorScheme === "dark" ? "#131415" : "#f9f9f9",
      color: theme.other.colors.primary.default,
    },
    settingsWrapper: {
      marginLeft: "auto",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      paddingRight: 8,
    },
  };
});

const WatchlistFooter = () => {
  const { classes } = useStyles()
  const dispatch = useDispatch()
  const [opened, { close, open }] = useDisclosure(false);
  const selectedWLIndex = useSelector(selectSelectedWLIndex)

  const [lastSavedIndex, setLastSavedIndex] = useLocalStorage({
    key: "lastWatchlistIndex",
    defaultValue: 0,
  })

  const [value, setValue] = useSessionStorage({
    key: "selectedWLIndex",
    defaultValue: selectedWLIndex || lastSavedIndex,
  })

  useEffect(() => {
    const handleBeforeUnload = () => {
      setLastSavedIndex(value)
    }
    window.addEventListener('beforeunload', handleBeforeUnload)
    return () => window.removeEventListener('beforeunload', handleBeforeUnload)
  }, [value, setLastSavedIndex])

  useEffect(() => {
    dispatch(setSelectedWatchlistIndex(value))
  }, [value, dispatch])

  const handleWatchlistSelect = (val: number) => {
    setValue(val)
    dispatch(setSelectedWatchlistIndex(val))
    dispatch(clearAddWLItem())
  }

  return (
    <Box className={classes.watchlistFooter}>
      {[1, 2, 3, 4, 5, 6, 7].map((item: number, index: number) => (
        <Box key={item}>
          <Tooltip
            label={`Watchlist ${index + 1}`}
            withArrow
            transitionProps={{ transition: 'scale', duration: 100 }}
          >
            <Box
              className={`${classes.watchlistFooterItem} ${index === selectedWLIndex ? classes.selectedIndex : {}}`}
              onClick={() => handleWatchlistSelect(index)}
            >
              {index + 1}
            </Box>
          </Tooltip>
        </Box>
      ))}
      <WatchlistSettings open={open} opened={opened} close={close} />
    </Box>
  )
}

export default memo(WatchlistFooter)

//  NOTE: added both local and session storage to save the watchlist index
//  local storage for single tab session
//  session storage for multiple tab sessions
