import { Flex } from '@uikit';
import {
  Box, Button, Divider, Group, Modal, Radio,
} from '@mantine/core'
import { notifications } from '@mantine/notifications';
import { IPositionItem } from '@redux/sagas/positions';
import { convertPostionOrder } from '@redux/sagas/trades/tradesSlice';
import { RootState } from '@redux/store';
import {
  FAILURE, SUCCESS, exchanges, productType,
} from '@utils/constant';
import { capitalizeFirstLetter, getNiceName, isEqual } from '@utils/index';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import useMediaCustom from 'src/hooks/common/useMediaCustom';
import { ArrowNarrowRight } from 'tabler-icons-react';
import usePrevious from '@hooks/common/usePrevious';
import { getUserPositions } from '@redux/sagas/positions/positionsSlice';

interface IConfirmCancelSingle {
  isVisible: boolean
  handleCloseConvertPosition: () => void;
  selectedPositionDetails: IPositionItem
}

const ConvertPostion = ({
  isVisible,
  handleCloseConvertPosition,
  selectedPositionDetails,
}: IConfirmCancelSingle) => {
  const { isMobile } = useMediaCustom();
  const dispatch = useDispatch();
  const { exchange, product } = selectedPositionDetails
  const { symbol, token, qty } = selectedPositionDetails
  const instrumentName = getNiceName(token, exchange, symbol)

  const convertOrderState = useSelector((state: RootState) => state.trade.convertOrder)
  const { status, data, loading } = convertOrderState
  const previousOrder = usePrevious({ convertOrderState });

  let convertValue = "";
  let selectedProduct = "";
  if ((exchange === exchanges.NSE || exchange === exchanges.BSE) && product === "C") {
    selectedProduct = "I"
    convertValue = "MIS"
  } else if ((exchange === exchanges.NFO || exchange === exchanges.BFO) && (product === "M")) {
    selectedProduct = "I"
    convertValue = "MIS"
  } else if (product === "I") {
    selectedProduct = exchange === exchanges.NFO || exchange === exchanges.BFO ? "M" : "C"
    convertValue = exchange === exchanges.NFO || exchange === exchanges.BFO ? "NRML" : "CNC"
  }

  const handleConvertPosition = () => {
    dispatch(convertPostionOrder({
      data: {
        exchange,
        symbol,
        token,
        quantity: String(Math.abs(qty)), // Should be sent as string
        product: selectedProduct,
        previousProduct: product,
        transactionType: qty > 0 ? "B" : "S",
        positionType: "Day",
      },
    }))
  }

  useEffect(() => {
    if (!isEqual(previousOrder.convertOrderState.status, status) && status === SUCCESS && data) {
      notifications.show({
        color: "blue",
        title: "Placed",
        message: `${capitalizeFirstLetter(data.message)}`,
      });
      handleCloseConvertPosition();
      setTimeout(() => {
        dispatch(getUserPositions());
      }, 1000)
    } else if (!isEqual(previousOrder.convertOrderState.status, status) && status === FAILURE && data) {
      notifications.show({
        color: "red",
        title: "Error",
        message: `${capitalizeFirstLetter(convertOrderState.message)}`,
      });
      handleCloseConvertPosition();
    }
  }, [status]);

  return (
    <Modal
      onClose={() => null}
      opened={isVisible}
      size="md"
      withCloseButton={false}
      fullScreen={!!isMobile}
    >
      <Box mb={10}>
        <Flex align="baseline">
          <p className="heading_sm_semibold mr-10">
            {instrumentName}
          </p>
          <p className="content_md_regular">{selectedPositionDetails.exchange}</p>
        </Flex>
      </Box>
      <Divider />
      <Flex mt={20} mb={20} justify="space-between">
        <Box>
          <p className="content_sm_regular text_fg_muted mb-4">
            Type
          </p>
          <Radio
            label="Day"
            checked
            size="xs"
          />
        </Box>
        <Box>
          <p className="content_sm_regular text_fg_muted mb-4">
            Product
          </p>
          <Flex>
            <p className="content_sm_regular text_fg_default">
              {productType[product]}
            </p>
            <p className="px-5"><ArrowNarrowRight size={14} /></p>
            <p className="content_sm_regular text_fg_default">
              {convertValue}
            </p>
          </Flex>
        </Box>
        <Box>
          <p className="content_sm_regular text_fg_muted mb-4">
            Quantity
          </p>
          <p className="content_sm_regular text_fg_default">{Math.abs(selectedPositionDetails.qty)}</p>
        </Box>
      </Flex>
      {/* modal button */}
      <Divider />
      <Group position="right" align="flex-start" mt="md">

        <Button
          onClick={handleConvertPosition}
          loading={loading}
        >
          Convert
        </Button>
        <Button
          variant="outline"
          onClick={handleCloseConvertPosition}
        >
          Close
        </Button>
      </Group>
    </Modal>
  )
}

export default ConvertPostion
