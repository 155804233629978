import { Flex } from '@uikit';
import { Text, useMantineTheme } from '@mantine/core';
import { IHoldingItem } from '@redux/sagas/holdings/holdingsTypes';
import { IGttOrderDetail, IUserOrder } from '@redux/sagas/orders';
import { extractTradeModalData, parsedSymbol } from '@utils/index';
import { IPositionItem } from '@redux/sagas/positions';
import FormattedName from '@components/watchlist/formattedName';
import OrderDropdown from '@containers/orders/executed/orderDropdown';
import { setTradeModalInfo } from '@redux/sagas/trades/tradesSlice';
import { useDispatch } from 'react-redux';

interface IOrderInstrument {
  row: IHoldingItem | IUserOrder | IPositionItem | IGttOrderDetail,
  width?: number
  type: string
  handleOrderDetailsModal?: any
}

const OrderInstrument = ({
  row,
  width = 200,
  type,
  handleOrderDetailsModal,
}: IOrderInstrument) => {
  const theme = useMantineTheme()
  const { symbol, exchange } = row;
  const parsedSymbolObj = parsedSymbol(symbol, exchange, row.token);

  const dispatch = useDispatch();
  const handleRepeatOrder = (item: IUserOrder) => {
    const {
      transactionType,
    } = item;
    dispatch(
      setTradeModalInfo({
        currentAssetItem: extractTradeModalData(item),
        buySellToggle: transactionType !== "B",
        showTradeModal: true,
      })
    );
  }

  return (
    <div
      style={{ width: `${width}px` }}
      className="table-intrument"
    >
      <Flex align="baseline">
        { parsedSymbolObj && (
        <FormattedName
          name={parsedSymbolObj.niceName}
          expiryDay={parsedSymbolObj.expiryDay}
          isWeekly={parsedSymbolObj.isWeekly}
          onClick={() => handleRepeatOrder(row as IUserOrder)}
        />
        )}
        <Text size={10} ml={3} color={theme.other.colors.fg.muted}>
          {row.exchange}
        </Text>
      </Flex>
      <OrderDropdown
        type={type}
        row={row}
        handleOrderDetailsModal={handleOrderDetailsModal}
      />
    </div>
  )
}

export default OrderInstrument
